import React, { useState } from 'react';
import './openEnded.sass';
import TextareaAutosize from 'react-textarea-autosize';
// import PropTypes from 'prop-types';

/* Component for open-ended question types.
Takes in a question and displays a text area for input
*/

const OpenEnded = ({ value, onChange, question }) => {
  const [inputLength, setInputLength] = useState(0);
  const inputLimit = question.maxLength;

  function handleComment(event) {
    onChange(
      { value: event.target.value, text: event.target.value },
      question.questionID,
      question.questionLevel,
    );
    setInputLength(event.target.value.length);
  }

  return (
    <div className="open-ended-container">
      <h4 className="range-score-question">
        <p className="question-text" dangerouslySetInnerHTML={{ __html: question.questionText }}>
          {/*{question?.required === true ? (*/}
          {/*  <span className="required-star">*</span>) : (<></>)}*/}
          {/*{' '}*/}
          {/*<strong>{question.questionText}</strong>*/}
        </p>
      </h4>
      {question.questionText !== 'You have already completed this survey.' && (
        <>
          <textarea
            rows={5}
            maxLength={inputLimit}
            className={`bg-[#F8F8F8] border border-[#E1EBFF] rounded-md px-4 py-2 ${
              question?.error ? 'flat-text-area__error' : ''
            }`}
            value={value}
            placeholder="Type your answer here..."
            onChange={handleComment}
            name="comment"
            required
          />
          <div className="input-comment">
            <span style={{ color: 'crimson' }}>
              <strong>{question?.error ? question?.errorMessage : ''} </strong>
            </span>
            <small className="character-count">
              {inputLength} / {inputLimit}
            </small>
          </div>
        </>
      )}
    </div>
  );
};

// OpenEnded.propTypes = {
//   onChange: PropTypes.func.isRequired,
//   value: PropTypes.string.isRequired,
//   question: PropTypes.objectOf(PropTypes.any).isRequired,
// };

export default OpenEnded;
