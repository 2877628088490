import React from 'react';
import './rangeQuestion.sass';
// import PropTypes from 'prop-types';

/* Component for range score question type.
Takes in a question and renders radio buttons from 0-10
*/

const RangeQuestion = ({ question, value, onChange }) => {
  function handleScore(event) {
    onChange(
      { value: event.target.value, text: event.target.value },
      question.questionID,
      question.questionLevel,
    );
  }

  let lowerLimit = 1;
  let upperLimit = 10;
  let limitArray = [];
  if (question?.validationRule != null) {
    const validationValue = question.validationRule.split('-');
    lowerLimit = parseInt(validationValue[0]);
    upperLimit = parseInt(validationValue[1]);
  }

  for (let i = lowerLimit; i <= upperLimit; i++) {
    limitArray.push(i);
  }

  return (
    <div className="range-container flex flex-col gap-2">
      <h4 className="range-score-question">
        <p
          className="question-text"
          dangerouslySetInnerHTML={{ __html: question.questionText }}
        ></p>
      </h4>
      <div className="range-form">
        <ul className="scale-container">
          {limitArray.map((num, i) => (
            <label
              className={value === num.toString() ? 'negative-range active' : 'negative-range'}
              key={i}
            >
              <input
                type="radio"
                name="NPS"
                value={num}
                onChange={handleScore}
                style={{ display: 'none' }}
              />
              <span>{num}</span>
            </label>
          ))}
          <label
            // kindly do not change this value, it will break functionality
            className={value === 'N/A skip' ? 'active positive-range' : 'positive-range'}
          >
            <input
              type="radio"
              name="NPS"
              value="N/A skip"
              onChange={handleScore}
              style={{ display: 'none' }}
            />
            <span style={{ fontSize: '0.8rem' }}>N/A</span>
          </label>
        </ul>
      </div>
    </div>
  );
};

// RangeQuestion.propTypes = {
//   onChange: PropTypes.func.isRequired,
//   value: PropTypes.string.isRequired,
//   question: PropTypes.objectOf(PropTypes.any).isRequired,
// };

export default RangeQuestion;
