import React from 'react';
import './singleSelect.sass';
// import PropTypes from 'prop-types';

/* Component for single select question types.
Takes in a question,
maps through the answer list and displays all possible answers */

const SingleSelect = ({ question, onChange, value }) => {
  function handleAnswer(event) {
    const index = event.target.value.split(',')[0];
    const text = event.target.value.split(',')[1];
    onChange({ value: index, text }, question.questionID, question.questionLevel);
  }

  question.answerList = ['YES', 'NO'];

  return (
    <div className="single-select">
      <h4 className="range-score-question">
        <p
          className="question-text mb-4"
          dangerouslySetInnerHTML={{ __html: question.questionText }}
        >
          {/*{question?.required === true ? (*/}
          {/*  <span className="required-star">*</span>) : (<></>)}*/}
          {/*<strong>{question.questionText}</strong>*/}
        </p>
      </h4>
      <div className="answer-list flex flex-col gap-4">
        {question &&
          question.answerList &&
          question.answerList.map((option, index) => (
            <label
              key={`${question.questionID}${index}`}
              className="w-full border border-[#E1EBFF] rounded-md flex bg-[#F8F8F8] py-2 px-4 hover:bg-[#E1EBFF] cursor-pointer text-black"
              htmlFor={`${question.questionID}${index}`}
            >
              <span>{option}</span>
              <input
                type="radio"
                id={`${question.questionID}${index}`}
                value={[index + 1, option]}
                onChange={handleAnswer}
                checked={(index + 1).toString() === value}
                className="invisible checked:bg-[#E1EBFF] checked:border-[#E1EBFF]"
              />
            </label>
          ))}
      </div>
    </div>
  );
};

// SingleSelect.propTypes = {
//   onChange: PropTypes.func.isRequired,
//   value: PropTypes.string.isRequired,
//   question: PropTypes.objectOf(PropTypes.any).isRequired,
// };
export default SingleSelect;
