import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import WebForm from '../components/webform.view';
import './landing.styles.scss';
import Loader from 'react-spinners/BounceLoader';
import { getQueryParameters } from '../utils/functions';

const API_URL = process.env.REACT_APP_API_URL;

export default function LandingPage() {
  const [commId, setCommId] = useState(null);
  const [joinCode, setJoinCode] = useState(null);
  const [isUrlValid, setIsURLValid] = useState(true);
  const [accountLogo, setAccountLogo] = useState('');
  const [hasEmail, setHasEmail] = useState(true);
  const [isEmailOrPhone, setEmailOrPhone] = useState(false);
  const [hasFacebook, setHasFacebook] = useState(false);
  const [hasTwitter, setHasTwitter] = useState(false);
  const [hasInstagram, setHasInstagram] = useState(false);
  const [userMetadata, setUserMetadata] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedContactMethod, setSelectedContactMethod] = useState('email');

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (!params.get('j')) {
      setIsURLValid(false);
    } else {
      setJoinCode(params.get('j'));
      setIsURLValid(true);

      const paramsToExclude = ['j', 'm'];
      const queryParams = getQueryParameters(location.search, paramsToExclude);

      setUserMetadata({ ...userMetadata, ...queryParams });

      axios
        .get(`${API_URL}/accounts/no-auth/web-form/${params.get('j')}`)
        .then((data) => {
          setAccountLogo(data.data.Data.logo);
          const surveyMetadata = data?.data?.Data?.surveyMetadata;

          try {
            if (surveyMetadata) {
              if (
                surveyMetadata?.find((metadata) => metadata?.name === 'webSurveyInputType')
                  ?.value === 'PHONE'
              )
                setHasEmail(false);
              if (
                surveyMetadata?.find((metadata) => metadata?.name === 'webSurveyInputType')
                  ?.value === 'PHONE_AND_EMAIL'
              )
                setEmailOrPhone(true);
              if (
                surveyMetadata?.find((metadata) => metadata?.name === 'webSurveyPlatform')
                  ?.value === 'TWITTER'
              )
                setHasTwitter(true);
              if (
                surveyMetadata?.find((metadata) => metadata?.name === 'webSurveyPlatform')
                  ?.value === 'INSTAGRAM'
              )
                setHasInstagram(true);
              if (
                surveyMetadata?.find((metadata) => metadata?.name === 'webSurveyPlatform')
                  ?.value === 'FACEBOOK'
              )
                setHasFacebook(true);
            }
            setLoading(false);
          } catch (e) {
            console.log(e);
          }
        })
        .catch((error) => console.error("Couldn't get account data : ", error));
    }
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    if (hasEmail) setCommId(formData.get('email'));
    else setCommId(formData.get('phone'));

    if (hasFacebook)
      setUserMetadata({ ...userMetadata, facebookUsername: formData.get('facebook') });
    if (hasTwitter) setUserMetadata({ ...userMetadata, twitterUsername: formData.get('twitter') });
    if (hasInstagram)
      setUserMetadata({ ...userMetadata, instagramUsername: formData.get('instagram') });
  };

  const handleOptionChange = (e) => {
    setSelectedContactMethod(e.target.value);
    if (e.target.value === 'email') {
      setHasEmail(true);
    } else {
      setHasEmail(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Ajua Webforms | Home</title>
      </Helmet>
      <div className="h-screen w-screen flex flex-col items-center justify-center bg-[#F8F8F8]">
        {loading && (
          <div
            style={{
              zIndex: '999999',
              background: 'white',
            }}
          >
            <div className="loader-container">
              <Loader
                // css={override}
                size={50}
                color="#2196f3"
                loading={loading}
              />
            </div>
          </div>
        )}
        {isUrlValid && !loading && (
          <>
            {commId ? (
              <WebForm joinCode={joinCode} commId={commId} userMetadata={userMetadata} />
            ) : (
              <div className="container landing-page-wrapper bg-white rounded-md p-8 px-8">
                <div className="customer-logo">
                  <img
                    src={accountLogo}
                    className="customer-account-avatar"
                    alt="account logo"
                  ></img>
                </div>
                <form onSubmit={onSubmit} style={{ maxWidth: '800px', margin: '0 auto' }}>
                  <p className="text-[#687588]">
                    To proceed with this survey, please provide us with your contact information.
                  </p>

                  {isEmailOrPhone && (
                    <div style={{ display: 'flex', gap: 20 }}>
                      <label style={{ fontWeight: 300 }}>
                        Your Email
                        <input
                          type="radio"
                          value="email"
                          checked={selectedContactMethod === 'email'}
                          onChange={handleOptionChange}
                        />
                      </label>

                      <label style={{ fontWeight: 300 }}>
                        Phone
                        <input
                          type="radio"
                          value="phone"
                          checked={selectedContactMethod === 'phone'}
                          onChange={handleOptionChange}
                        />
                      </label>
                    </div>
                  )}
                  <div className="row g-3 mt-3">
                    {hasEmail ? (
                      <div className="col">
                        <label htmlFor="ajWebformEmailInput" className="mb-2 block text-[#1F2937]">
                          Your Email
                        </label>
                        <input
                          type="email"
                          className="border border-gray-300 rounded-md w-full px-4 py-2"
                          id="ajWebformEmailInput"
                          name="email"
                          required
                          placeholder="juma@mail.com"
                        />
                      </div>
                    ) : (
                      <div className="col">
                        <label htmlFor="webFormPhoneInput" className="form-label mb-2 fw-300">
                          Phone No.
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          id="webFormPhoneInput"
                          name="phone"
                          required
                          placeholder="0712345678"
                          pattern="[0-9]{10}"
                        />
                      </div>
                    )}
                  </div>
                  <div className="row g-3 mt-3">
                    {hasFacebook && (
                      <div className="col">
                        <label htmlFor="webFormFacebook" className="form-label mb-2 fw-300">
                          Facebook username
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="webFormFacebook"
                          name="facebook"
                          required
                          placeholder="jumaHassan"
                        />
                      </div>
                    )}
                    {hasTwitter && (
                      <div className="col">
                        <label htmlFor="webFormTwitter" className="form-label mb-2 fw-300">
                          Twitter Username
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="webFormTwitter"
                          name="twitter"
                          required
                          placeholder="jumaHassan"
                        />
                      </div>
                    )}
                    {hasInstagram && (
                      <div className="col">
                        <label htmlFor="webFormInstagram" className="form-label mb-2 fw-300">
                          Instagram username
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="webFormInstagram"
                          name="instagram"
                          required
                          placeholder="jumaHassan"
                        />
                      </div>
                    )}
                  </div>
                  <div className="mt-1 mb-3">
                    <small className="small my-1 text-[#A0AEC0]" style={{ fontSize: 10 }}>
                      By providing your information, you agree to sharing your feedback guided by
                      our{' '}
                      <a
                        href="https://ajua.com/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline"
                      >
                        Privacy Policy
                      </a>
                    </small>
                  </div>
                  <button
                    className="text-white rounded-full bg-[#464BDE] px-4 py-2 flex items-center"
                    type="submit"
                  >
                    Proceed{' '}
                    <span>
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.88586 14.8778C8.23367 15.2073 8.79759 15.2073 9.14539 14.8778L14.1923 10.0966C14.5401 9.76712 14.5401 9.23288 14.1923 8.90338L9.14539 4.12213C8.79759 3.79262 8.23367 3.79262 7.88586 4.12213C7.53808 4.45163 7.53808 4.98587 7.88586 5.31537L12.303 9.5L7.88586 13.6847C7.53808 14.0142 7.53808 14.5483 7.88586 14.8778Z"
                          fill="#FAFAFA"
                        />
                      </svg>
                    </span>
                  </button>
                </form>
              </div>
            )}
          </>
        )}
        <section className="w-full absolute bottom-0 flex flex-col items-center gap-2 pb-2 bg-white mt-4">
          <hr className="w-full" />
          <div className="flex flex-col md:flex-row justify-between items-center container">
            {commId && <span className="x-small">You are completing this survey as {commId}</span>}
            <p className="text-sm flex items-center">
              <span>Powered by </span>
              <a href="https://ajua.com" target={'_blank'} rel="noopener noreferrer">
                <img
                  src="/icons/icon-512x512.png"
                  className="ajua-logo-image"
                  alt="ajua logo"
                  style={{ height: '2rem' }}
                />
              </a>
            </p>
          </div>
        </section>
      </div>
      {!isUrlValid && <Navigate to="/404" />}
    </>
  );
}
